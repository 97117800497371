export const ROUTES = {
  root: "/",
  ticketMovement: "ticket-movement/:id",
  projects: "projects",
  analytics: "analytics",
  mySuppliers: "my-suppliers",
  ticketsRating: "tickets-rating",
  registryUsers: "registry-users",
  createProject: "create-project",
  editNewProject: "create-project/:id",
  editProject: "edit-project/:id",
  login: "login",
  tickets: "tickets",
  estimateAdd: "estimate-add/:id",
  estimate: "estimate/:id",
  confirmation: "confirmation",
  positions: "positions/:id",
  registerTrade: "register-trade",
  notFound: "*",
  notifications: "notifications",
  createTicket: "create-ticket",
  editTicket: "create-ticket/:ticketId",
  approveSupplier: "approve-supplier/:id",
  createProtocol: "create-protocol/:id",
  myProfile: "my-profile",
  addUser: "add-user",
  editUser: "edit-user/:id",
  invoices: "invoices",
  invoice: "invoice/:id",
} as const;
