import React from "react";
import * as Sentry from "@sentry/react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import {extractChunkUrl, isNetworkError} from "./utils/helpers";
import {ResourceLoadError} from "./types/Errors";

Sentry.init({
  dsn: process.env.NODE_ENV === "production" ? process.env.REACT_APP_DSN : undefined,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  environment: process.env.NODE_ENV,
  tracesSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
  tracePropagationTargets: ["localhost", /^https:\/\/api-stage/],
  replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 0,
  replaysOnErrorSampleRate: process.env.NODE_ENV === "production" ? 1.0 : 0,
  normalizeDepth: 10,
});

Sentry.addEventProcessor(async (event, hint) => {
  const error = hint.originalException as Error;
  const chunkLoadRegExp = /Error: Loading (css)?chunk/i;

  if (
    error?.message &&
    typeof error.message === "string" &&
    chunkLoadRegExp.test(error.message)
  ) {
    const url = extractChunkUrl(error);

    if (await isNetworkError(url)) {
      event.level = "warning"; // Если это ошибка сети, отправляем как предупреждение
    } else {
      Sentry.captureException(new ResourceLoadError(url || "Unknown chunk URL"));
    }
  }

  return event;
});
