import {lazy, Suspense} from "react";
import {useInitApp} from "shared/hooks/useInitApp";
import SuspenseLoader from "./ui/SuspenseLoader";
const AppComponent = lazy(() => import("./ui/AppComponent"));

function App() {
  const {isLoading} = useInitApp();

  if (isLoading) {
    return <SuspenseLoader />;
  }

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <AppComponent />
    </Suspense>
  );
}

export default App;
