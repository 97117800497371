import {BaseQueryApi, QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import type {FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import * as Sentry from "@sentry/react";
import {getErrorLevel} from "./helpers";
import {RTKQueryError} from "../types/Errors";

type RTKErrorDescriptionType = {
  args: string | FetchArgs;
  api: BaseQueryApi;
  extraOptions: unknown;
  result: QueryReturnValue<unknown, FetchBaseQueryError, unknown>;
};

export const handleRTKErrorBySentry = ({
  args,
  api,
  extraOptions,
  result,
}: RTKErrorDescriptionType) => {
  const status = result.error?.status;
  const level = getErrorLevel(status);

  const error = new RTKQueryError(status, args, api);

  Sentry.captureException(error, {
    level,
    extra: {
      args,
      api,
      extraOptions,
      result,
    },
  });
};
