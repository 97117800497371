import type {Decorator, Parameters} from "@storybook/react";
import {Provider} from "react-redux";
import {PreloadedState} from "redux";
import {RootState, storiesStore} from "shared/store/store";

type CustomDecorator = {
  parameters?: {
    store?: {
      initialState: PreloadedState<RootState>;
    };
  } & Parameters;
} & Decorator;

// для сторибука
const StoreProvider: CustomDecorator = (StoryFn, {parameters}) => {
  // TODO типизировать parameters
  console.log("--==I am StoreProvider==--");
  const currStore = storiesStore(parameters);
  return (
    <Provider store={currStore}>
      <StoryFn />
    </Provider>
  );
};

export {StoreProvider};
