import {AlertColor} from "@mui/material/Alert";
import {SyntheticEvent, useState} from "react";
import errorMP3 from "shared/static/sound/notice-error.mp3";
import successMP3 from "shared/static/sound/notice-success.mp3";

/**
 * Используется для управления контекста в провайдере SnackbarWrapper
 */
export const useSnackbar = () => {
  const [open, setOpen] = useState(false);
  const [textSnackbar, setTextSnackbar] = useState("");
  const [colorSnackbar, setColorSnackbar] = useState<AlertColor>("info");
  const [title, setTitleSnackbar] = useState("");

  const audioError = new Audio(errorMP3);
  const audioSuccess = new Audio(successMP3);

  const audioMap: Record<AlertColor, HTMLAudioElement> = {
    error: audioError,
    warning: audioError,
    info: audioSuccess,
    success: audioSuccess,
  };

  const handleShowSnackbar = (text: string, color: AlertColor, title = "") => {
    setOpen(true);
    setTextSnackbar(text);
    setColorSnackbar(color);
    setTitleSnackbar(title);

    const audio = audioMap[color];
    audio.play().catch((e) => console.error("Audio play failed", e));
  };

  const handleClose = (event: SyntheticEvent | Event | null, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };
  return {
    open,
    handleShowSnackbar,
    colorSnackbar,
    textSnackbar,
    handleClose,
    title,
  };
};
