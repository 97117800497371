import {RouterProvider, createBrowserRouter} from "react-router-dom";
import {APP_ROUTES} from "../routeConfig/routeConfig";
import {LazyLoading} from "shared/components/LazyLoading";
import * as Sentry from "@sentry/react";

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter(APP_ROUTES, {
  future: {v7_normalizeFormMethod: true},
});

export default function () {
  return <RouterProvider router={router} fallbackElement={<LazyLoading />} />;
}
