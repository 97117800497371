import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {generateActionsForSync} from "shared/utils/generateActionsForSync";
// import {NotificationService} from "shared/services/NotificationService";
import {
  INotification,
  INotificationState,
  TNotificationsFilterState,
  TSelectOneNotification,
} from "./notifications.types";
import {initialState} from "./initialState";
// import {WS_SERVERS} from "shared/constants/wsServers";
// import {ON_PAGE} from "pages/Notifications/constants";
// import {v4} from "uuid";
// import {resetState} from "shared/store/resetState";
import {BcService} from "shared/services/BroadcastChannelService";
import {NotificationsEvents} from "shared/utils/notificationUtils/notificationsEvents";
import {
  DeleteNotificationsNotificationsV1DeleteNotificationsPostApiResponse,
  GetMyNotificationsV1GetNotificationsPostApiResponse,
  GetMySubscriptionsSettingsNotificationsV1GetSubscriptionsSettingsPostApiResponse,
  GetUnreadCountNotificationsV1GetUnreadNotificationsCountPostApiResponse,
  MarkAllIsReadHttpResponse,
  MarkAllIsReadNotificationsV1MarksAllIsReadPostApiResponse,
  MarkIsReadNotificationsV1MarkIsReadPostApiResponse,
  MarksIsReadNotificationsV1MarksIsReadPostApiResponse,
} from "RTKQuery/types/notificationsApi";

const initEvents = [
  {
    event: NotificationsEvents.NotificationService.GetUnreadNotificationsCount,
    message: {},
  },
];

export const notificationSlice = createSlice({
  name: "notificationSlice",
  initialState,
  reducers: {
    // ...resetState,
    clearNotificationsState: () => initialState,
    setIsInit: (state, {payload}: PayloadAction<boolean>) => {
      state.isInit = payload;
    },
    notificationsInit: (state) => {
      state.isInit = true;
      initEvents.forEach(({event, message}) => {
        state.loaders[event] = true;
        BcService.handleBcSendGlobalMessage({
          request: {
            message,
            event: NotificationsEvents.NotificationServiceEvent + event,
          },
        });
      });
      // state.onError = payload.onError;
    },
    assignNotificationState: (state, {payload}) => {
      Object.assign(state, payload);
    },
    setUnreadNotificationCnt: (
      state,
      {
        payload,
      }: PayloadAction<
        GetUnreadCountNotificationsV1GetUnreadNotificationsCountPostApiResponse["message"]
      >,
    ) => {
      state.unreadCount = payload.unreadCount;
    },
    /** обновление списка уведомлений, общего кол-ва и страниц при открытии страницы "Уведомления" */
    setNotifications: (
      state,
      {
        payload,
      }: PayloadAction<GetMyNotificationsV1GetNotificationsPostApiResponse["message"]>,
    ) => {
      state.notifications = [
        ...state.notifications,
        ...payload.items,
      ] as unknown as INotification[];

      // времянка пока Алексей не починит тип уведомления в сваггере

      state.totalItemsCount = payload.totalItemsCount;
      state.totalPagesCount = payload.totalPagesCount;
    },

    setNotificationsAsNew: (
      state,
      {
        payload,
      }: PayloadAction<GetMyNotificationsV1GetNotificationsPostApiResponse["message"]>,
    ) => {
      state.notifications = payload.items as unknown as INotification[];
      state.totalItemsCount = payload.totalItemsCount;
      state.totalPagesCount = payload.totalPagesCount;
    },
    /** сброс списка уведомлений при закрытии страницы "Уведомления", чтобы не хранить эти данные на фронте */
    resetNotificationsArr: (state) => {
      state.notifications = [];
    },
    /** обновление списка уведомлений, общего кол-ва и страниц при открытии страницы "Уведомления" */
    setNotificationsSettings: (
      state,
      {
        payload,
      }: PayloadAction<
        GetMySubscriptionsSettingsNotificationsV1GetSubscriptionsSettingsPostApiResponse["message"]
      >,
    ) => {
      state.settings = payload;
    },
    /** Обработчик при прочтении одного из уведомлений */
    setNotificationIsRead: (
      state,
      {
        payload,
      }: PayloadAction<MarkIsReadNotificationsV1MarkIsReadPostApiResponse["message"]>,
    ) => {
      state.unreadCount = state.unreadCount > 0 ? --state.unreadCount : 0;
      if (!state.notifications.length) return;
      state.notifications = state.notifications.map((notification) =>
        notification.id === payload?.id
          ? {...notification, isRead: true, isReadAt: payload.isReadAt}
          : notification,
      );
    },
    /** Обработчик при прочтении нескольких уведомлений */
    setNotificationsIsRead: (
      state,
      {
        payload,
      }: PayloadAction<MarksIsReadNotificationsV1MarksIsReadPostApiResponse["message"]>,
    ) => {
      // TODO отрефакторить после изменения типов по уведомлениям
      const {ids, isReadAt} = payload as any;
      const newUnreadCount = state.unreadCount - ids.length;
      state.unreadCount = newUnreadCount < 0 ? 0 : newUnreadCount;
      if (!state.notifications.length) return;
      state.notifications = state.notifications.map((notification) =>
        ids.includes(notification.id)
          ? {...notification, isRead: true, isReadAt}
          : notification,
      );
    },
    /** Обработчик при прочтении всех уведомлений */
    setAllNotificationIsRead: (
      state,
      {
        payload,
      }: PayloadAction<
        MarkAllIsReadNotificationsV1MarksAllIsReadPostApiResponse["message"]
      >,
    ) => {
      state.unreadCount = 0;
      if (!state.notifications.length) return;
      state.notifications = state.notifications.map((notification) => ({
        ...notification,
        isRead: true,
        isReadAt: payload.isReadAt,
      }));
    },
    /** Обработчик при удалении нескольких уведомлений (обработчика удаления одного уведомления нет, поскольку выполняет аналогичную функцию)*/
    setNotificationsDelete: (
      state,
      {
        payload,
      }: PayloadAction<
        DeleteNotificationsNotificationsV1DeleteNotificationsPostApiResponse["message"]
      >,
    ) => {
      if (!state.notifications.length) return;
      state.notifications = state.notifications.filter(
        (notification) => !payload.ids.includes(notification.id),
      );
    },
    changeSubscription: (state, {payload}) => {
      const setting = state.settings?.find(({name}) => name === payload.name);
      const event = setting?.events.find(({id}) => id === payload.eventId);
      const port = event?.ports.find(({port}) => port === payload.port);
      port!.subscription = payload.value;
    },
    selectNotification: (state, {payload}: PayloadAction<TSelectOneNotification>) => {
      // отметить все
      if (payload.all) {
        return {
          ...state,
          notifications: state.notifications?.map((notification) => ({
            ...notification,
            isChecked: payload.flag,
          })),
        };
      }
      // отметить одно
      return {
        ...state,
        notifications: state.notifications?.map((notification) =>
          notification.id === payload.id
            ? {...notification, isChecked: payload.flag}
            : notification,
        ),
      };
    },
    setNotificationCurrentPage: (state, {payload}: PayloadAction<number>) => {
      state.currentPage = payload;
    },
    // для частичного обновления фильтров на получение уведомлений
    setNotificationFiltersState: (
      state,
      {payload}: PayloadAction<TNotificationsFilterState>,
    ) => {
      state = {
        ...state,
        currentPage: payload.currentPage || state.currentPage,
        currSort: payload.currSort || state.currSort,
        servicesArr: payload.servicesArr || state.servicesArr,
      };
    },
    // для обнуления состояния фильтров на получение уведомлений
    resetNotificationFiltersState: (state) => {
      state = {
        ...state,
        currentPage: initialState.currentPage,
        currSort: initialState.currSort,
        servicesArr: initialState.servicesArr,
      };
    },
    setNotificationLoaders: (
      state,
      {payload}: PayloadAction<Partial<INotificationState["loaders"]>>,
    ) => {
      state.loaders = {...state.loaders, ...payload};
    },
    /** обработчик при получении нового уведомления */
    setLastNotification: (state, {payload}: PayloadAction<INotification | null>) => {
      // если получаем уведомление
      if (payload) {
        // TODO: дождаться пока Алексей доделает кол-во непрочитанных уведомлений в payload.
        // т.к счетчик неверный потому что не все уведомления записываются в БД и отображаются в общем списке

        state.unreadCount = state.unreadCount + 1;
        // и при этом на вкладке уведомлений на первой странице списка
        if (state.currentPage === 0) {
          state.notifications?.unshift(payload);
          if (state.notifications.length > 9) {
            state.notifications.pop();
          }
        }
      }
      state.lastNotification = payload;
    },
    // minusUnreadNotification: (state, {payload}) => {
    //   state.unreadCount = payload
    //     ? state.unreadCount - payload
    //     : state.unreadCount - 1;
    // },
  },
});

export const {
  clearNotificationsState,
  setIsInit,
  notificationsInit,
  assignNotificationState,
  setUnreadNotificationCnt,
  setNotifications,
  setNotificationsAsNew,
  resetNotificationsArr,
  setNotificationsSettings,
  setNotificationIsRead,
  setNotificationsIsRead,
  setAllNotificationIsRead,
  setNotificationsDelete,
  // onMessageNotifications,
  changeSubscription,
  selectNotification,
  setNotificationCurrentPage,
  setNotificationFiltersState,
  resetNotificationFiltersState,
  setNotificationLoaders,
  setLastNotification,
  // minusUnreadNotification,
} = notificationSlice.actions;

export const notificationSliceActions = generateActionsForSync(notificationSlice, {
  blackList: [
    "setIsInit",
    "setNotificationCurrentPage",
    "setNotificationFiltersState",
    "resetNotificationFiltersState",
    "assignNotificationState",
    "setNotifications",
    "setNotificationsAsNew",
    "resetNotificationsArr",
    "selectNotification",
    "setNotificationCurrentPage",
    "setNotificationFiltersState",
    "resetNotificationFiltersState",
  ],
});

export default notificationSlice.reducer;
