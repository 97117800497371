import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import React, {Component, PropsWithChildren, PropsWithRef, ReactNode} from "react";
import {errorLoggerService} from "shared/services/errorLoggerService/errorLoggerService";
import {store} from "shared/store/store";

const ErrorComponent = () => {
  return (
    <Stack
      sx={{
        width: "100vw",
        height: "100vh",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Stack sx={{minHeight: "50vh", alignItems: "center", gap: 2}}>
        <Typography component={"h2"} sx={{fontSize: 24, fontWeight: "bold"}}>
          Необходимо обновить страницу
        </Typography>
        <Button onClick={() => window.location.reload()}>обновить</Button>
      </Stack>
    </Stack>
  );
};

//логирование ошибок
const handleSendError = (error, info) => {
  // setTimeout(() => {
  //   location.href = "/";
  // }, 4000);
  errorLoggerService.log({
    error: JSON.stringify(error, Object.getOwnPropertyNames(error)),
    info,
    store: store.getState(),
    pathname: window.location.pathname,
    userId: store.getState().UserRegisterSlice.userData?.id,
  });
};

interface Props {
  children?: ReactNode;
  fallbackComponent?: React.ReactElement<
    unknown,
    string | React.FunctionComponent | typeof React.Component
  > | null;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<PropsWithRef<PropsWithChildren<Props>>, State> {
  constructor(props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error, info) {
    if (process.env.NODE_ENV === "production") {
      handleSendError(error, info);
    }
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    console.error(error);
    //логирование ошибок
    if (process.env.NODE_ENV === "production") {
      handleSendError(error, info);
    }
  }

  render() {
    if (this.state?.hasError) {
      if (this.props.fallbackComponent) return this.props.fallbackComponent;
      return <ErrorComponent />;
    }
    return this.props?.children;
  }
}

export default ErrorBoundary;
