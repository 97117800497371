import {ACTIONS} from "../../const/rolesActions/actions";
import {ROUTES} from "../../const/paths/paths";
import {RolesFunctionFourEnum} from "RTKQuery/types/srmApi";

export interface IConfig {
  actions: (typeof ACTIONS)[keyof typeof ACTIONS][];
  routes: (typeof ROUTES)[keyof typeof ROUTES][];
}

/**
 * Доступные роуты или действия по функциональным ролям
 */
export const functionalPermissions: Record<RolesFunctionFourEnum, IConfig> = {
  "Менеджер проекта": {
    actions: [ACTIONS.addOrEditeContactInProject, ACTIONS.allowCreateTicketSchema],
    routes: [
      ROUTES.estimateAdd,
      ROUTES.createProject,
      ROUTES.editNewProject,
      ROUTES.editProject,
      ROUTES.confirmation,
      ROUTES.positions,
      ROUTES.ticketMovement,
      ROUTES.ticketsRating,
      ROUTES.analytics,
    ],
  },
  Инициатор: {
    actions: [
      ACTIONS.allowedClose,
      ACTIONS.allowedCloseTicket,
      ACTIONS.allowedSetPositionStatusToGet,
      ACTIONS.addOrEditeContactInProject,
    ],
    routes: [
      ROUTES.createTicket,
      ROUTES.editTicket,
      ROUTES.positions,
      ROUTES.registryUsers,
      ROUTES.ticketMovement,
      ROUTES.ticketsRating,
      ROUTES.analytics,
    ],
  },
  Исполнитель: {
    actions: [
      ACTIONS.allowedSetStatusPositionToBillIsPayed,
      ACTIONS.allowedSetStatusPositionToOnTrade,
      ACTIONS.allowedSetQuantityOfPositionStorage,
      ACTIONS.navigateToProcedureTicket,
      ACTIONS.navigateToChooseSupplier,
      ACTIONS.navigateToApproveExecutor,
      ACTIONS.navigateToGenerateProtocol,
      ACTIONS.allowedDeleteTrade,
      ACTIONS.canDeletePositionInvoice,
    ],
    routes: [
      ROUTES.approveSupplier,
      ROUTES.registerTrade,
      ROUTES.positions,
      ROUTES.createProtocol,
      ROUTES.invoices,
      ROUTES.invoice,
    ],
  },
  Контролёр: {
    actions: [ACTIONS.navigateToApproveController],
    routes: [
      ROUTES.approveSupplier,
      ROUTES.registerTrade,
      ROUTES.invoices,
      ROUTES.invoice,
    ],
  },
};

/**
 * Доступные страницы (роуты) обычного пользователя
 */
const simpleUserAllowerRoutes: IConfig["routes"] = [
  ROUTES.notFound,
  ROUTES.myProfile,
  ROUTES.notifications,
  ROUTES.projects,
  ROUTES.root,
  ROUTES.tickets,
  ROUTES.estimate,
  //
  ROUTES.mySuppliers,
  ROUTES.ticketsRating,
  ROUTES.analytics,
  ROUTES.ticketMovement,
  ROUTES.registryUsers,
];
/**
 * Доступные действия обычного пользователя
 */
const simpleUserAllowerActions: IConfig["actions"] = [ACTIONS.navigateToTradeProcedure];
/**
 * Доступные роуты или действия по системным ролям
 */
export const systemPermissionsRecord: Record<string, IConfig> = {
  Владелец: {
    actions: [...simpleUserAllowerActions],
    routes: [
      ...simpleUserAllowerRoutes,
      ROUTES.editUser,
      ROUTES.addUser,
      ROUTES.approveSupplier,
      ROUTES.registerTrade,
      ROUTES.positions,
      ROUTES.invoices,
      ROUTES.invoice,
    ],
  },
  Администратор: {
    actions: [...simpleUserAllowerActions],
    routes: [...simpleUserAllowerRoutes, ROUTES.approveSupplier, ROUTES.registerTrade],
  },
  Пользователь: {
    actions: simpleUserAllowerActions,
    routes: simpleUserAllowerRoutes,
  },
  unAuth: {
    actions: [],
    routes: [ROUTES.login, ROUTES.notFound],
  },
};
