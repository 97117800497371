import {setIsMainTabApp, setTabsCount} from "shared/ReduxSlices/bcSlice/bcSlice";
import {BcService} from "shared/services/BroadcastChannelService";

const currTab = new Date().getTime();
let sharedWorkerTabs: number[] = [currTab];
let timerID: NodeJS.Timeout;

export const BCWorkerListener = (dispatch) => {
  
  const mainTabMessageHandler = ({data}: MessageEvent) => {
    if (data.connectTab || data.disconnectTab) {
      if (data.connectTab && !sharedWorkerTabs.includes(data.connectTab)) {
        sharedWorkerTabs = [...sharedWorkerTabs, data.connectTab].sort();
        BcService.handleBcSendMessage({connectTab: currTab});
      } else if (data.disconnectTab) {
        sharedWorkerTabs = sharedWorkerTabs.filter((tab) => tab !== data.disconnectTab);
      } else {
        return;
      }
      dispatch(setTabsCount(sharedWorkerTabs.length));
      clearTimeout(timerID);
      timerID = setTimeout(() => {
        const isMain = sharedWorkerTabs[0] === currTab;
        console.log({isMain, sharedWorkerTabs, currTab});
        dispatch(setIsMainTabApp(isMain));
      }, 200);
    }
  };

  BcService.handleBcSendMessage({connectTab: currTab});

  dispatch(setTabsCount(sharedWorkerTabs.length));
  timerID = setTimeout(() => {
    const isMain = sharedWorkerTabs[0] === currTab;
    dispatch(setIsMainTabApp(isMain));
  }, 300);

  const unloadHandler = () => {
    BcService.handleBcSendMessage({disconnectTab: currTab});
    BcService.messageReceiver.removeEventListener("message", mainTabMessageHandler);
  };

  BcService.messageReceiver.addEventListener("message", mainTabMessageHandler);
  window.addEventListener("beforeunload", unloadHandler);
};
