import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initialState";
import {matchRoutes} from "react-router-dom";
import {startAppListening} from "shared/store/middlewares/listenerMiddleware";
import {createAppConfig} from "shared/utils/createAppConfig/createAppConfig";
import {ROUTES} from "shared/constants/paths";
import {functionalPermissions} from "shared/SRM_shared/config/permissionConfig/permissionConfig";
import {RolesFunctionFourEnum} from "RTKQuery/types/srmApi";

export const userSRMSlice = createSlice({
  name: "userSRMSlice",
  initialState,
  reducers: {
    selectRole: (state, action: PayloadAction<RolesFunctionFourEnum>) => {
      state.selectedRole = action?.payload;
      localStorage.setItem("role", action?.payload);
    },
    removeRole: (state) => {
      state.selectedRole = null as any;
    },
    setIdForUserInfo: (state, action: PayloadAction<string | null>) => {
      state.idForUserInfo = action.payload;
    },
    setIdForContactInfo: (state, action: PayloadAction<"new" | "current" | null>) => {
      state.idForContactInfo = action.payload;
    },
    // для добавления контакта в проект
    setIdForProjectContact: (state, action: PayloadAction<string | null>) => {
      state.idContactProject = action.payload;
    },
  },
});

export const {actions: userActions} = userSRMSlice;
export const {reducer: userReducer} = userSRMSlice;

startAppListening({
  actionCreator: userActions.selectRole,
  effect: (action, listenerApi) => {
    // console.log(listenerApi);
    if (action.payload && action.payload in functionalPermissions) {
      //routes - доступные урлы
      const {routes} = functionalPermissions[action.payload];
      //route.path - на каком урле сейчас пользователь
      // eslint-disable-next-line no-restricted-globals
      const routeMatch = matchRoutes(createAppConfig(ROUTES.SRM + "/"), location, "/");
      const route = routeMatch ? routeMatch[0].route : null;
      if (route?.path && !(routes as string[]).includes(route?.path)) {
        console.log("need redirect to projects");
        return;
      }
    }
  },
});
