import {connected, initWS, disconnected} from "../ReduxSlices/webSocketSlice/WSSlice";
import {WS_SERVERS} from "../constants/wsServers";
import {IWSState} from "shared/ReduxSlices/webSocketSlice/types";
import {store} from "shared/store/store";
import {ridCounter} from "./ridCounter";
import {BcService} from "shared/services/BroadcastChannelService";
import {authCustomApi} from "entities/user";
import {setUserId} from "shared/ReduxSlices/chatSlice/chatSlice";

let webSockets = {} as IWSState;
export const connectionAccepted = [initWS, setUserId];

let reconnectAttempts = 0;

/**
 * @description Функция принимает сервер к которому будет подключаться, хранилище и кол-во попыток для подключения
 * @description Функцию можно вызывать много раз, она будет создавать одно подключение
 * @param {{name: string, url: string}} server
 * @param maxReconnectAttempts - максимальное кол-во попыток для подключения
 * @return {Promise<unknown>}
 */
export const webSocketConnectV2 = ({
  server = WS_SERVERS.v2,
  maxReconnectAttempts,
}: {
  server?: {name: string; url: string};
  maxReconnectAttempts: number;
}): Promise<unknown> =>
  new Promise((res, rej) => {
    const bsSlice = store.getState().broadcastChannelSlice;
    const dispatch = store.dispatch;
    let connectedID = "";
    // если можно подключаться, подключается главная вкладка
    if (bsSlice.isMainApp) {
      // получаем разовый токен для подключения по ws
      _getToken()
        .then((token) => {
          if ("error" in token) {
            return console.error(token.error);
          }
          connectedID = token?.data?.token || connectedID;
          // подключаемся к сокетам
          webSockets[server.name] = {
            ...webSockets[server.name],
            ws: new WebSocket(server.url + connectedID),
          };
          return webSockets[server.name].ws;
        })
        .then((ws) => {
          if (!ws) return;

          ws.onmessage = async (data) => {
            const json = JSON.parse((await data.data.text()) || "");
            if (json.event === "ConnectionAccepted") {
              webSockets[server.name] = {
                ...webSockets[server.name],
                isConnected: true,
                userId: json.message.userId,
              };
              // console.log({json, server: server.name, connectionAccepted});
              connectionAccepted.forEach((action) =>
                dispatch(action({...json, server: server.name})),
              );
            }
            console.log("Ответ ws: ", json);
            BcService.handleBcSendGlobalMessage({
              response: json,
              meta: {
                userId: store.getState()?.webSocketSlice?.[server.name]?.userId,
              },
            });
          };

          const handleBsListener = (messageEvent: MessageEvent<any>) => {
            if (messageEvent && messageEvent.data.request) {
              const {
                message = {},
                event = "",
                rid = ridCounter(),
              } = messageEvent.data.request;
              try {
                process.env.NODE_ENV === "development" &&
                  console.log("Запрос ws: ", {message, event, rid});
                if (
                  typeof event === "string" &&
                  (typeof rid === "string" || typeof rid === "number") &&
                  typeof message === "object"
                ) {
                  ws.readyState === 1 &&
                    event &&
                    ws.send(
                      JSON.stringify({message, event, rid, rmeta: message?.meta || {}}),
                    );
                }
              } catch (e) {
                console.warn("не удалось отправить данные, ошибка: ", e);
              }
            }
          };

          ws.onopen = () => {
            webSockets[server.name] = {...webSockets[server.name], connectedID};
            dispatch(connected({server: server.name, connectedID}));
            BcService.messageReceiver.addEventListener("message", handleBsListener);
            // console.log("i connected " + connectedID);
            res({idConnected: connectedID, ws});
            reconnectAttempts = 0;
          };

          ws.onclose = (e) => {
            dispatch(disconnected({server: server.name}));
            webSockets[server.name].ws = null;
            console.log("Соединение WebSocket разорвано. Причина: " + e.reason);
            BcService.messageReceiver.removeEventListener("message", handleBsListener);
            if (_handleErrorCode(e.code) && reconnectAttempts < maxReconnectAttempts) {
              reconnectAttempts++;
              setTimeout(
                function () {
                  webSocketConnectV2({server, maxReconnectAttempts});
                },
                10000 * (reconnectAttempts + 1),
              );
            }

            return console.error(e);
          };
        })
        .catch((err) => console.error(err));
    }
  });

//функция отключения сокетов, принимает причину закрытия и сервер, например WS_SERVERS.v2
// если не передавать сервер, то разрываются все соединения
export function webSocketDisconnect(reason = "logout", server = {name: ""}) {
  if (server && webSockets[server.name]) {
    console.log("i am closed");
    webSockets[server.name]?.ws?.close();
  } else {
    Object.values(webSockets).forEach((x) => x.ws?.close(1000, reason));
  }
}

async function _getToken() {
  const {dispatch} = store;
  return await dispatch(
    authCustomApi.endpoints.authV2OneTimeTokenGet.initiate(undefined, {track: false}),
  );
  // return await  axiosGlobal().get("auth/v2/one-time-token");
}

/** обработчик ошибки от ws. возвращает необходимость повторного подключения */
function _handleErrorCode(code: number): boolean {
  switch (code) {
    case 1006: // WebSocket connection to 'wss://api-stage.ab-trade.ru/ws/v2/066614fa-fe84-71c7-f1c7-d08509aceac8' failed: (e.reason пустой)
      // https://kapeli.com/cheat_sheets/WebSocket_Status_Codes.docset/Contents/Resources/Documents/index --> CLOSE_ABNORMAL
      console.error("connection by WebSocket is failed");
      return true;
      break;
    case 3409: // User already connected
      BcService.handleBcSendGlobalMessage({alreadyConnected: true});
      return false;
      break;
    case 3423: // Session Expired
      BcService.handleBcSendGlobalMessage({expired: true});
      return false;
      break;
    case 3424: // User manually logged out
      BcService.handleBcSendGlobalMessage({manuallyLoggedOut: true});
      return false;
      break;
    case 3425: // User just logged in from some other place
      BcService.handleBcSendGlobalMessage({loggedOtherPlace: true});
      return false;
      break;
    case 3426: // User is blocked at company level
      BcService.handleBcSendGlobalMessage({blockedCompanyLevel: true});
      return false;
      break;
    case 3427: // User is blocked at system level
      BcService.handleBcSendGlobalMessage({blockedSystemLevel: true});
      return false;
      break;
    case 3428: // Company of user is blocked at system level
      BcService.handleBcSendGlobalMessage({CompanyBlocked: true});
      return false;
      break;
    case 3503: // Application shutdown ("умер" один из сервисов, что привело к закрытию соединения по ws)
      console.error("Application shutdown");
      return true;
      break;
    default:
      return false;
  }
}
