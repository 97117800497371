import {INotificationState} from "./notifications.types";

export const initialState: INotificationState = {
  isInit: false,
  currentItemsCount: null,
  currentPage: 0,
  currSort: false,
  servicesArr: [],
  isLoading: false,
  notifications: [],
  totalItemsCount: 0,
  totalPagesCount: 0,
  loaders: {
    GetNotifications: false,
    GetSubscriptionsSettings: false,
    GetUnreadNotificationsCount: false,
    MarkIsRead: false,
    MarksIsRead: false,
    MarksAllIsRead: false,
    SetSubscription: false,
    SetSubscriptions: false,
    DeleteNotification: false,
    DeleteNotifications: false,
    NewNotification: false,
  },
  requests: {},
  settings: null,
  unreadCount: 0,
  lastNotification: null,
  // onError: () => undefined,
};
