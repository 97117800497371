import Button from "@mui/material/Button";

const URL =
  "https://api-stage.ab-trade.ru/s3files/41a646ee-55a2-4fb4-b468-96c83937d7fb" as const;

const UploadFile = ({setFile}) => {
  const download = async () => {
    const fileData = await fetch(URL).then((res) => {
      console.log(res);
      return res.blob();
    });
    const file = new File([fileData], "скачаный файл");
    console.log(file);
    setFile(file);
  };

  return (
    <Button variant="outlined" onClick={download} sx={{width: "100%"}}>
      Скачать файл
    </Button>
  );
};

export default UploadFile;
