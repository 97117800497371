import UserRegisterSlice from "shared/ReduxSlices/userSlice/userSlice";
import chatSlice from "shared/ReduxSlices/chatSlice/chatSlice";
import WSSlice from "shared/ReduxSlices/webSocketSlice/WSSlice";
import broadcastChannelSlice from "shared/ReduxSlices/bcSlice/bcSlice";
import notificationSlice from "shared/ReduxSlices/notificationsSlice/notificationsSlice";
import {api} from "RTKQuery/api";
import {userReducer} from "shared/ReduxSlices/srmUserSlice/userSRMSlice";
import tradeSlice from "shared/ReduxSlices/tradeSlice/tradeSlice";

export const reducers = {
  UserRegisterSlice,
  chatSlice,
  webSocketSlice: WSSlice,
  broadcastChannelSlice,
  notificationSlice,
  userSRMSlice: userReducer,
  tradeSlice,
};

export const reducersForStore = {
  ...reducers,
  api: api.reducer,
};
