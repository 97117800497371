import {lazy} from "react";
import {RouteObject} from "react-router-dom";
import {ROUTES} from "shared/constants/paths";

const ForgotPassword = lazy(() => import("pages/Dashboard/Dashboard"));
const UserRegister = lazy(() => import("pages/Register/UserRegister/UserRegister"));
const Verification = lazy(() => import("pages/Register/Verification/Verification"));
const ForConsumersV2 = lazy(() => import("pages/ForConsumers/ForConsumersV2"));
const ForProviderV2 = lazy(() => import("pages/ForProvider/ForProviderV2"));

export const authRoutes: RouteObject[] = [
  {
    path: ROUTES.PROVIDER,
    element: <ForProviderV2 />,
  },
  {
    path: ROUTES.CONSUMER,
    element: <ForConsumersV2 />,
  },
  {
    path: ROUTES.VERIFICATION,
    element: <Verification />,
  },
  {
    path: ROUTES.REGISTRATION_USER,
    element: <UserRegister />,
  },
  {
    path: ROUTES.FORGOT_PASSWORD,
    element: <ForgotPassword />,
  },
];
