export interface IProcedureState {
  // isLoading: boolean;
  // procedureList: any[];
  // filters: any;
  // statuses: any[];
  // formats: any[];
  // types: any[];
  // sort_trend: boolean;
  // dataError: any;
  // totalCount: 0;
  // prevCurrentPath: string;
  // sort_by: string;
  // myProcedures: string;
  // preTotalCount: any;
  // isLoadingPre: boolean;
  // procedure: any;
  // isLoadingFiles: boolean;
  // isLoadingLots: boolean;
  // isLoadingCompany: boolean;
  // protocols: any[];
  // isLoadingProtocols: boolean;
  // questions: null;
  // isLoadingQuestions: boolean;
  // explanationsErrors: null;
  // isLoadingDocuments: boolean;
  // documents: any;
  // events: any[];
  // isLoadingEvents: boolean;
  // dashboard: {
  //   proceduresList: {list: []; types: []};
  //   participationList: {list: []; types: []};
  // };
  // dashboardLoadings: any[];
  // subscriptions: any[];
  // isLoadingSubscriptions: boolean;
  // rebiddingList: any;
  // isLoadingRebiddingList: boolean;
  // используется для выбора процедуры на которую подается заявка (если заявки нга участие еще нет)
  procedureId: string | null;
}
export const initialState = {
  // isLoading: false,
  // procedureList: [],
  // filters: {},
  // statuses: [],
  // formats: [],
  // types: [],
  // sort_trend: true,
  // dataError: {},
  // totalCount: 0,
  // prevCurrentPath: "",
  // sort_by: "time_publication",
  // myProcedures: "",
  // preTotalCount: null,
  // isLoadingPre: false,
  // procedure: {},
  // isLoadingFiles: false,
  // isLoadingLots: false,
  // isLoadingCompany: false,
  // protocols: [],
  // isLoadingProtocols: false,
  // questions: null,
  // isLoadingQuestions: false,
  // explanationsErrors: null,
  // isLoadingDocuments: false,
  // documents: {},
  // events: [],
  // isLoadingEvents: false,
  // dashboard: {
  //   proceduresList: {list: [], types: []},
  //   participationList: {list: [], types: []},
  // },
  // dashboardLoadings: [],
  // subscriptions: [],
  // isLoadingSubscriptions: false,
  // rebiddingList: null,
  // isLoadingRebiddingList: false,
  // используется для выбора процедуры на которую подается заявка (если заявки нга участие еще нет)
  procedureId: null,
};
