import Box, {BoxProps} from "@mui/material/Box";
import {useMemo} from "react";
import {useAppColorThemeContext} from "shared/hooks/useAppColorThemeContext/useAppColorThemeContext";

/**
 *  Карточка с тенью и радиусом
 */
function UI_BoxShadowCard({children, ...otherProps}: BoxProps) {
  const {sx, ...other} = otherProps;
  const {colorMode} = useAppColorThemeContext();
  const isLight = useMemo(() => colorMode === "light", [colorMode]);
  return (
    <Box
      sx={({palette}) => ({
        borderRadius: 2,
        boxShadow: isLight
          ? "0px 1px 8px 0px rgba(217, 217, 217, 0.85)"
          : "0px 1px 8px 0px rgba(30, 30, 30, 0.85)",
        transition: "all 0.3s ease",
        ":hover": {
          boxShadow: isLight
            ? "0px 1px 8px 0px rgba(49, 172, 184, 0.4)"
            : "0px 1px 8px 0px rgba(49, 172, 184, 0.4)",
        },
        background: palette.backgroundBlock.default,
        ...sx,
      })}
      {...other}
    >
      {children}
    </Box>
  );
}

export default UI_BoxShadowCard;
