import {Components, Theme} from "@mui/material/styles";
import {renderScrollStyle} from "shared/components/UI/UI_ScrollContainer/utils/ScrollStyle";
import {emptyListText} from "shared/constants/commonText";

export const muiSelect: Components<Theme>["MuiSelect"] = {
  defaultProps: {
    fullWidth: true,
    value: emptyListText,
    MenuProps: {
      sx: ({palette}) => ({
        ".MuiMenu-paper": {
          ...renderScrollStyle(palette),
        },
      }),
    },
  },
  styleOverrides: {
    select: ({theme}) => ({
      height: "34px",
      paddingTop: 4,
      paddingBottom: 4,
      display: "flex",
      alignItems: "center",
    }),
  },
};
