import {CommonPermissions} from "shared/constants/permissions";
import {ROUTES as ROUTES_PATH} from "../../constants/paths";
export type TProtectedRoute = {
  type: "public" | "auth" | "private" | "forbidden";
};
type TPermission = (typeof CommonPermissions)[keyof typeof CommonPermissions];
interface IRoute<T> {
  path: T;
  name: string;
  searchDefault?: string;
  index?: boolean;
  type: TProtectedRoute["type"];
  permissionNeed?: TPermission | TPermission[];
}

type TRoutes = {
  [Property in keyof typeof ROUTES_PATH]: IRoute<(typeof ROUTES_PATH)[Property]>;
};

export const PROTECTED_ROUTES_CONFIG: TRoutes = {
  // TICKETS_COMPANY_SEARCH: {
  //   name: "",
  //   path: "/tickets-company/search/:page/:query",
  // },
  // TICKETS_SEARCH: {
  //   name: "",
  //   path: "/tickets/search/:page/:query",
  // },
  FINANCE: {path: "/finance", name: "Финансы", type: "private"},
  CONSUMER: {path: "/customers", name: "Заказчикам", type: "public"},
  PROVIDER: {path: "/suppliers", name: "Поставщикам", type: "public"},
  NEWS: {path: "/news", name: "Новости", type: "public"},
  NEWS_DETAIL: {path: "/news/:id", name: "Новости", type: "public"},
  REGISTRATION_USER: {
    path: "/registration",
    name: "Регистрация пользователя",
    type: "auth",
  },
  TICKETS: {
    path: "/tickets",
    name: "Заявки",
    type: "private",
  },
  // SAFE_REGISTRY: {
  //   path: "/safe-registry",
  //   name: "Безопасная сделка",
  // },
  TICKETS_PAGE: {
    path: "/tickets/:page",
    name: "Заявки",
    type: "private",
  },

  TICKETS_COMPANY: {
    path: "/tickets-company",
    name: "Заявки организации",
    type: "private",
  },
  TICKETS_COMPANY_PAGE: {
    path: "/tickets-company/:page",
    name: "Заявки организации",
    type: "private",
  },

  TICKET_DETAIL_PROCEDURE: {
    path: "/tickets/procedure/:id",
    name: "Детали заявки",
    type: "private",
  },

  TICKET_DETAIL_COMPANY: {
    path: "/tickets/company/:id",
    name: "Детали заявки",
    type: "private",
  },

  REGISTRATION_COMPANY: {
    path: "/registration-company",
    name: "Регистрация компании",
    type: "private",
  },
  HOME: {path: "/", name: "Главная", type: "public"},
  VERIFICATION: {
    path: "/registration/verification/:code",
    name: "Подтверждение регистрации",
    type: "auth",
  },
  CATALOG: {path: "/catalog", name: "Каталог", type: "public"},
  SRM: {
    path: "/srm",
    name: "SRM",
    type: "public",
  },
  // FZ_223: {
  //   path: "/223-fz",
  //   name: "Законодательство",
  //   type: "public",
  // },
  BZ: {
    path: "/bz",
    name: "База знаний",
    type: "public",
  },
  BLOG: {
    path: "/blog",
    name: "Блог",
    type: "public",
  },
  BLOG_ID: {
    path: "/blog/:id",
    name: "Блог",
    type: "public",
  },
  TRADE_CREATE_PURCHASES: {
    path: "/trade/trade-create-purchases",
    name: "Создать процедуру",
    type: "private",
  },

  TRADE_CREATE: {
    path: "/trade/trade-create",
    name: "Создать процедуру",
    type: "private",
  },

  TRADE: {path: "/trade", name: "Торговая площадка", index: true, type: "public"},
  TRADE_DETAIL: {
    path: "/procedure/:idProc",
    name: "Просмотр процедуры",
    type: "public",
  },

  PARTICIPATION_IN_PROCEDURE: {
    path: "/trade/participation",
    name: "Подача заявки",
    type: "private",
  },
  PARTICIPATION_TICKETS: {
    path: "/trade/participations",
    name: "Заявки на участие",
    type: "private",
  },
  TRADE_EDIT: {
    path: "/trade/trade-edit/:id",
    name: "Редактировать процедуру",
    type: "private",
  },
  PARTICIPATION_TICKET: {
    path: "/trade/participation/:ticket_participation_id",
    name: "Заявка на участие",
    type: "private",
  },
  PART_OF_PARTICIPANT_TICKET: {
    path: "/ticket_participation/:ticket_participation_id",
    name: "Заявка участника",
    type: "private",
  },
  COMPANIES: {path: "/companies", name: "Реестр компаний", type: "forbidden"},
  COMPANY: {
    path: "/companies/:inn",
    name: "*&^*(&^*%^%^&%^&%",
    type: "private",
  },
  QUOTES: {path: "/quotes", name: "Котировки", type: "public"},
  SERVICES: {path: "/services", name: "Сервисы", type: "public"},
  SERVICES_COMPANY_VERIFICATION: {
    path: "/services/company-verification",
    searchDefault: "?service=briefReport",
    name: "Проверка компании",
    type: "public",
  },
  SERVICES_FINAN: {
    path: "/services/company-verification",
    searchDefault: "?service=finan",
    name: "Финансовый анализ",
    type: "public",
  },
  SERVICES_115FZ: {
    path: "/services/company-verification",
    searchDefault: "?service=115-fz",
    name: "Проверка по 115-ФЗ",
    type: "public",
  },
  SERVICES_USRLE: {
    path: "/services/company-verification",
    searchDefault: "?service=excerpt",
    name: "Выписка из ЕГРЮЛ",
    type: "public",
  },
  TARIFFS: {path: "/tariffs", name: "Тарифы", type: "public"},
  SAFETY: {path: "/safety", name: "Безопасность", type: "public"},
  ABOUT_US: {path: "/about-us", name: "О нас", type: "public"},
  FEEDBACK: {path: "/feedback", name: "Обратная связь", type: "public"},
  SEARCH: {path: "/site-search", name: "Результаты поиска", type: "public"},
  DASHBOARD: {
    path: "/dashboard",
    name: "Личный кабинет",
    type: "private",
  },
  PROFILE: {
    path: "/profile",
    name: "Мой профиль",
    type: "private",
  },
  COMPANY_PROFILE: {
    path: "/company-profile",
    name: "Информация о компании",
    type: "private",
    permissionNeed: "company:edit_profile",
  },
  USERS: {
    path: "/company-users",
    name: "Пользователи",
    type: "private",
  },
  COMPANY_USER_PROFILE: {
    path: "/company-users/:id",
    name: "Редактировать пользователя",
    type: "private",
    permissionNeed: "employees:update_profile",
  },
  NOTIFICATION_CONTROL: {
    path: "/notification-control",
    name: "Управление уведомлениями",
    type: "private",
  },
  NOTIFICATIONS: {
    path: "/notifications",
    name: "Уведомления",
    type: "private",
  },
  FORGOT_PASSWORD: {
    path: "/users/confirmation-forgot-password/:code",
    name: "Восстановление пароля",
    type: "auth",
  },

  404: {
    path: "/*",
    name: "Страница не найдена",
    type: "public",
  },
  JOIN_TO_COMPANY: {
    path: "/join-to-company",
    name: "Присоединиться к компании",
    type: "private",
  },

  FAQ: {
    path: "/faq",
    name: "Вопрос-Ответ",
    type: "public",
  },

  USER_MANUAL: {
    path: "/user-manual",
    name: "Руководство пользователя",
    type: "public",
  },

  SAFE_DEAL: {
    path: "/safe-deal",
    name: "Безопасная сделка",
    type: "public",
  },

  SAFE_DEAL_EDIT: {
    path: "/safe-deal/:id",
    name: "Редактирование безопасной сделки",
    type: "private",
  },
  SAFE_HISTORY_DEAL: {
    path: "/safe-deal-history/:id",
    name: "История безопасной сделки",
    type: "private",
  },
  ANALITICS: {
    path: "/analitics",
    name: "Аналитика",
    type: "public",
  },
  MY_COUNTERPARTIES: {
    path: "/my-counterparties",
    name: "Мои контрагенты",
    type: "private",
  },
  TRANSACTION_EVALUATION: {
    path: "/transaction-evaluation",
    name: "Оценка сделок",
    type: "private",
  },
  TEST_CRYPTO_PRO: {
    name: "криптопро",
    path: "/test-crypto-pro",
    type: "private",
  },
  DOCUMENTS: {
    path: "/documents",
    name: "Документация",
    type: "public",
  },
  AUDIT: {
    path: "/diagnostic",
    name: "Диагностика системы снабжения",
    type: "public",
  },
} as const;
