import {FetchArgs} from "@reduxjs/toolkit/dist/query";
import {BaseQueryApi} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

export class RTKQueryError extends Error {
  constructor(
    status: string | number | undefined,
    args: string | FetchArgs,
    api: BaseQueryApi,
  ) {
    const url =
      typeof args === "object" && "url" in args
        ? args.url
        : `${api.endpoint} ${api.type}`;

    const message = `${status} - ${url}`;

    super(message);

    this.name = `RTKQueryError - ${api.endpoint}`;
  }
}

export class ResourceLoadError extends Error {
  constructor(resourceUrl: string) {
    super(`Failed to load resource: ${resourceUrl}`);
    this.name = "ResourceLoadError";
  }
}
