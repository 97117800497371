import {TInitialStateChat} from "./types";

export const initialStateChat: TInitialStateChat = {
  isInit: false,
  userId: "",
  GetUnreadMessagesCnt: 0,
  // ChatOnUsers: {},
  GetChats: {chats: [], users: []},
  GetArchivedChats: {chats: [], users: []},
  GetBlackListChats: {chats: [], users: []},
  GetMessages: {},
  GetMessagesScroll: true,
  GetMedia: [],
  typeCreateState: "",
  currentChat: undefined,
  findChatsText: "",
  findChatsFileText: "",
  currentChatMenu: "MESSAGES_LIST",
  isChatUsersListOpen: false,
  currentMainMenu: "COMMON",
  isFullScreen: false,
  isMobileView: localStorage.getItem("chat_isMobileView") === "true",
  isOpenChat: false,
  loaders: {
    Archive: false,
    ChatClosed: false,
    ChatCreated: false,
    ChatDeleted: false,
    ChatGroupAddUsersSuccess: false,
    ChatLeaved: false,
    ChatOnUsers: false,
    ChatOpened: false,
    ConnectionAccepted: false,
    DirectCreate: false,
    DirectInviteAccept: false,
    DirectInviteReject: false,
    DirectSearch: false,
    GetArchivedChats: false,
    GetBlackListChats: false,
    GetChat: false,
    GetChats: false,
    GetMedia: false,
    GetMessages: false,
    GetUnreadMessagesCnt: false,
    GroupAddUsers: false,
    GroupCreate: false,
    GroupDelete: false,
    GroupInviteAccept: false,
    GroupInviteReject: false,
    GroupLeave: false,
    GroupSearch: false,
    InProtocol: false,
    InProtocolSuccess: false,
    InvitationRequest: false,
    InviteAccepted: false,
    InviteRejected: false,
    IsDelivered: false,
    IsRead: false,
    MeetingClose: false,
    MeetingClosed: false,
    MeetingCreate: false,
    MeetingInviteAccept: false,
    MeetingInviteReject: false,
    MeetingSearch: false,
    MessageReceived: false,
    MessageSend: false,
    ProtocolCreated: false,
    SendSuccess: false,
    Typing: false,
    UserBlacklist: false,
    UserIsOffline: false,
    UserIsOnline: false,
    UploadFiles: false,
  },
  isMuteChat: false,
  // findTextFiles: "",
  // openRightMenu: false,
  DirectSearch: [],
  GroupSearch: [],
  MeetingSearch: [],
  lastCreatedChat: undefined,
  lastRededMessage: undefined,
  droppedFiles: null,
};
