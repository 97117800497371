const warningStatuses = [400, 401, 402, 403, 406, 409, 423, 422, 429];
// 404 ??
export const extractChunkUrl = (error: Error): string | null => {
  const matches = error.message.match(/Loading (css)?chunk (\d+)/i);
  return matches ? `/static/js/${matches[2]}.js` : null;
};

export const isNetworkError = async (url: string | null): Promise<boolean> => {
  if (!url) return false;

  try {
    const response = await fetch(url);
    return !response.ok;
  } catch {
    return true;
  }
};

export const getErrorLevel = (status?: string | number): "error" | "warning" => {
  if (typeof status === "string") return "error";

  if (status && warningStatuses.includes(status)) {
    return "warning";
  }
  return "error";
};

export const simpleCheckIsItStage = (): boolean => {
  return !!process.env.REACT_APP_API_URL?.includes("stage");
};
