import {createSlice} from "@reduxjs/toolkit";
import {resetState} from "../../store/resetState";
import {initialState} from "./type";

export const tradeSlice = createSlice({
  name: "tradeSlice",
  initialState,

  reducers: {
    ...resetState,
    setProcedureId(state, {payload}) {
      state.procedureId = payload;
    },
  },
});

export const {setProcedureId} = tradeSlice.actions;

export default tradeSlice.reducer;
