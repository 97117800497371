import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import {API_URL} from "shared/constants/API";
import queryString from "query-string";
import {tagTypes_SRM} from "shared/constants/apiTags";
import type {BaseQueryFn, FetchArgs, FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {on406} from "shared/ReduxSlices/bcSlice/bcSlice";
import {handleRTKErrorBySentry} from "app/providers/Sentry/utils/handleRTKErrorBySentry";

export const sharedAppsTags = ["tickets", "ticketDetail", "user"] as const;

const baseQuery = retry(
  fetchBaseQuery({
    baseUrl: API_URL,
    credentials: "include",
    paramsSerializer(params) {
      return queryString.stringify(params, {arrayFormat: "bracket"});
    },
  }),
  {maxRetries: 0},
);

const baseQueryWithIntercept: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result.error) {
    console.warn("---===!!!result.error!!!===---", result.error);
    handleRTKErrorBySentry({args, api, extraOptions, result});
  }
  
  if (result.error && result.error.status === 406) {
    console.warn("---===!!!406 code!!!===---");
    api.dispatch(on406());
  }
  return result;
};

export const api = createApi({
  reducerPath: "api",
  tagTypes: [...sharedAppsTags, ...tagTypes_SRM],
  baseQuery: baseQueryWithIntercept,

  endpoints: (b) => ({}),
});
