import {lazy, Suspense} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {useTheme} from "@mui/material/styles";
import {useInitApp} from "shared/hooks/useInitApp";
import SuspenseLoader from "./ui/SuspenseLoader";
import {useMainTabListener} from "shared/hooks/useMainTabListener";
import {useBcHelpers} from "shared/hooks/useBcHelpers";
const AppComponent = lazy(() => import("./ui/AppComponent"));

function App() {
  const {isLoading} = useInitApp();
  const {palette} = useTheme();
  useMainTabListener();
  useBcHelpers();

  if (isLoading) {
    // console.log(`i am working on ${process.env.NODE_ENV} mode`);

    return (
      <Stack
        height={"100vh"}
        p={4}
        alignItems={"center"}
        justifyContent={"center"}
        width={"100%"}
      >
        <Typography mb={2}>Загрузка...</Typography>
        <CircularProgress
          color="primary"
          sx={{width: "100%", color: palette.primary.main}}
        />
      </Stack>
    );
  }

  return (
    <Suspense fallback={<SuspenseLoader />}>
      <AppComponent />
    </Suspense>
  );
}

export default App;
