import "./app/providers/Sentry";

import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import {WithStoreProvider} from "app/providers/StoreProvider";
import {WithErrorBoundary} from "app/providers/ErrorBoundary";
import {RouterProvider} from "app/providers/RouterProvider";
import {WithThemeProvider} from "app/providers/ThemeProvider";
import {WithColorThemeProvider} from "app/providers/WithColorThemeProvider";
import {compose} from "lodash/fp";
import {WithSnackbarProvider} from "app/providers/SnackbarProvider";
import {WithDragAndDropProvider} from "app/providers/DragAndDropProvider";
import {WithModalProvider} from "app/providers/ModalProvider";

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  compose(
    WithStoreProvider,
    WithErrorBoundary,
    WithColorThemeProvider,
    WithThemeProvider,
    WithSnackbarProvider,
    WithDragAndDropProvider,
    WithModalProvider,
  )(RouterProvider),
);

reportWebVitals();
