import Typography, {TypographyProps} from "@mui/material/Typography";
import Box, {BoxProps} from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import UI_BoxShadowCard from "../UI_BoxShadowCard/UI_BoxShadowCard";
import CircularProgress from "@mui/material/CircularProgress";

type TProps = {
  variant?: "info" | "loading" | "error";
  TypographyProps?: TypographyProps;
} & BoxProps;

// карточка с системным сообщением
// https://www.figma.com/design/QFdqQx86CqUZGE6sVcZAfc/%D0%9D%D0%95-%D0%92%D0%95%D0%A0%D0%A1%D0%A2%D0%90%D0%A2%D0%AC-%D0%98%D0%97-%D0%AD%D0%A2%D0%9E%D0%93%D0%9E-%D0%A4%D0%90%D0%99%D0%9B%D0%90-%D0%AD%D0%A2%D0%9E-%D0%A7%D0%95%D0%A0%D0%9D%D0%9E%D0%92%D0%98%D0%9A?node-id=11728-10329&node-type=canvas&t=TmSYNTs81xw3pL6N-0
function UI_SystemMessageBox({
  variant = "info",
  children,
  TypographyProps = {},
  ...props
}: TProps) {
  const {sx, ...rest} = props;
  const {sx: TypographySx, ...TypographyRest} = TypographyProps;
  const {palette, breakpoints} = useTheme();

  if (variant === "loading") {
    return (
      <Box
        sx={{
          marginY: 10,
          marginX: "auto",
          display: "flex",
          flexDirection: "row",
          gap: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography> {children}</Typography>
        <CircularProgress color="primary" size={20} />
      </Box>
    );
  }

  return (
    <UI_BoxShadowCard
      sx={{
        width: "100%",
        maxWidth: 440,
        height: 240,
        borderRadius: 4,
        padding: 5,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginY: 10,
        marginX: "auto",
        [breakpoints.down(768)]: {padding: "10px 16px"},
        ...sx,
      }}
      {...rest}
    >
      <Typography
        variant="16px"
        sx={{
          color: variant === "error" ? palette.red800.main : "inherit",
          ...TypographySx,
        }}
        {...TypographyRest}
      >
        {children}
      </Typography>
    </UI_BoxShadowCard>
  );
}

export default UI_SystemMessageBox;
