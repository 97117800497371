import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {resetState} from "../../store/resetState";
import {generateActionsForSync} from "../../utils/generateActionsForSync";
import {IWSState} from "./types";

export const WSSlice = createSlice({
  name: "webSocket",
  initialState: {} as IWSState,
  reducers: {
    ...resetState,
    connected: (state, {payload}: PayloadAction<{server: string}>) => {
      const {server} = payload;
      console.log("WSSlice connected trigger");
      state[server] = {isLoading: false, isConnected: true};
    },
    disconnected: (state, {payload}: PayloadAction<{server: string}>) => {
      const {server} = payload;
      state[server] = {...state[server], isLoading: false, isConnected: false};
    },
  },
});

// export const WSSliceActions = generateActionsForSync(WSSlice);
export const WSSliceActions = generateActionsForSync(WSSlice, {
  blackList: ["connected", "disconnected"],
});
export const {connected, disconnected} = WSSlice.actions;
export default WSSlice.reducer;
