// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_appWrapper__2Rzy2 {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

.styles_padding__BCfZ- {
  padding: 0 40px;
}
@media (max-width: 768px) {
  .styles_padding__BCfZ- {
    padding: 0 20px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/components/AppWrapper/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,iBAAA;EACA,cAAA;AACF;;AAEA;EACE,eAAA;AACF;AACE;EAHF;IAII,eAAA;EAEF;AACF","sourcesContent":[".appWrapper {\n  width: 100%;\n  max-width: 1440px;\n  margin: 0 auto;\n}\n\n.padding {\n  padding: 0 40px;\n\n  @media (max-width: 768px) {\n    padding: 0 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appWrapper": `styles_appWrapper__2Rzy2`,
	"padding": `styles_padding__BCfZ-`
};
export default ___CSS_LOADER_EXPORT___;
