import Person from "@mui/icons-material/Person";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {FC, ReactNode} from "react";

interface UI_ProfileProps {
  photoUrl?: string | null;
  title?: string;
  isSkeleton?: boolean;
  fio: string;
  children: ReactNode;
}

const UI_Profile: FC<UI_ProfileProps> = ({
  photoUrl,
  title = "",
  isSkeleton,
  fio,
  children,
}) => {
  return (
    <Box>
      <Typography paragraph variant="h1-medium-20" mb={"24px"}>
        {title}
      </Typography>
      <Stack mb={"24px"}>
        <Stack direction={"row"} mb={"24px"} gap={"16px"}>
          <Avatar sx={{width: 74, height: 74}} src={photoUrl as string}>
            {photoUrl ? null : <Person sx={{width: 66, height: 66}} />}
          </Avatar>
          <Stack justifyContent={"center"}>
            <Typography paragraph variant="h3-medium-13" sx={{mb: "4px"}}>
              ФИО
            </Typography>
            <Typography variant="h3-regular-13">
              {isSkeleton ? <Skeleton variant="rectangular" width={100} /> : fio}
            </Typography>
          </Stack>
        </Stack>
        {children}
      </Stack>
    </Box>
  );
};

export {UI_Profile};
