import {lazy} from "react";
import {RouteObject} from "react-router-dom";
import {ROUTES} from "shared/constants/paths";

const TransactionEvaluation = lazy(
  () => import("pages/TransactionEvaluation/TransactionEvaluation"),
);
const MyCounterparties = lazy(() => import("pages/MyCounterparties/MyCounterparties"));
const Analitics = lazy(() => import("pages/Analitics/Analitics"));
const SafeHistoryDeal = lazy(() => import("pages/SafeDealHistory/SafeDealHistory"));
const SaveDealPage = lazy(() => import("pages/SaveDealPage/SaveDealPage"));
const JoinToCompany = lazy(() => import("pages/JoinToCompany/JoinToCompany"));
const NotificationsPage = lazy(() => import("pages/NotificationsPage/NotificationsPage"));
const NotificationsControl = lazy(
  () => import("pages/NotificationsControl/NotificationsControl"),
);
const CompanyUserProfile = lazy(
  () => import("pages/CompanyUserProfile/CompanyUserProfile"),
);
const CompanyUsers = lazy(() => import("pages/CompanyUsers/CompanyUsers"));
const CompanyProfile = lazy(() => import("pages/CompanyProfile"));
const Profile = lazy(() => import("pages/Profile/Profile"));
const Dashboard = lazy(() => import("pages/Dashboard/Dashboard"));
const CompanyVerification = lazy(
  () =>
    import(
      "pages/ServicesPage/comsponents/servicesComponents/Analitic/CompanyVerification/CompanyVerification"
    ),
);
const Company = lazy(() => import("pages/Company/Company"));
const PartisipationDetail = lazy(
  () => import("pages/Trade/PartisipationDetail/PartisipationDetail"),
);
const ParticipationInProcedure = lazy(
  () => import("pages/Trade/ParticipationInProcedureV2"),
);
const CreateTrade = lazy(() => import("pages/Trade/CreateTrade/CreateTrade"));
const CreateTradePurchases = lazy(
  () => import("pages/CreateTradePurchases/CreateTradePurchases"),
);
const CompanyRegisterV2 = lazy(
  () => import("pages/Register/CompanyRegisterV2/CompanyRegisterV2"),
);

const Tickets = lazy(() => import("pages/Tickets/Tickets"));
const ParticipationTickets = lazy(
  () => import("pages/Trade/ParticipationTickets/ParticipationTickets"),
);
const Finance = lazy(() => import("pages/Finance"));

export const privateRoutes: RouteObject[] = [
  {
    path: ROUTES.TRANSACTION_EVALUATION,
    element: <TransactionEvaluation />,
  },
  {
    path: ROUTES.MY_COUNTERPARTIES,
    element: <MyCounterparties />,
  },
  {
    path: ROUTES.ANALITICS,
    element: <Analitics />,
  },
  {
    path: ROUTES.SAFE_HISTORY_DEAL,
    element: <SafeHistoryDeal />,
  },
  {
    path: ROUTES.SAFE_DEAL_EDIT,
    element: <SaveDealPage />,
  },
  {
    path: ROUTES.JOIN_TO_COMPANY,
    element: <JoinToCompany />,
  },
  {
    path: ROUTES.NOTIFICATIONS,
    element: <NotificationsPage />,
  },
  {
    path: ROUTES.NOTIFICATION_CONTROL,
    element: <NotificationsControl />,
  },
  {
    path: ROUTES.COMPANY_USER_PROFILE,
    element: <CompanyUserProfile />,
  },
  {
    path: ROUTES.USERS,
    element: <CompanyUsers />,
  },
  {
    path: ROUTES.COMPANY_PROFILE,
    element: <CompanyProfile />,
  },
  {
    path: ROUTES.PROFILE,
    element: <Profile />,
  },
  {
    path: ROUTES.DASHBOARD,
    element: <Dashboard />,
  },
  {
    path: ROUTES.SERVICES_COMPANY_VERIFICATION,
    element: <CompanyVerification />,
  },
  {
    path: ROUTES.COMPANY,
    element: <Company />,
  },
  {
    path: ROUTES.PART_OF_PARTICIPANT_TICKET,
    element: <PartisipationDetail />,
  },

  {
    path: ROUTES.PARTICIPATION_TICKET,
    element: <ParticipationInProcedure />,
  },
  {
    path: ROUTES.TRADE_EDIT,
    element: <CreateTrade />,
  },
  {
    path: ROUTES.PARTICIPATION_IN_PROCEDURE,
    element: <ParticipationInProcedure />,
  },
  {
    path: ROUTES.TRADE_CREATE,
    element: <CreateTrade />,
  },
  {
    path: ROUTES.TRADE_CREATE_PURCHASES,
    element: <CreateTradePurchases />,
  },
  {
    path: ROUTES.REGISTRATION_COMPANY,
    element: <CompanyRegisterV2 />,
  },

  {
    path: ROUTES.TICKET_DETAIL_COMPANY,
    element: <CompanyRegisterV2 />,
  },

  {
    path: ROUTES.TICKETS_COMPANY_PAGE,
    element: <Tickets />,
  },
  {
    path: ROUTES.TICKETS_COMPANY,
    element: <Tickets />,
  },

  {
    path: ROUTES.TICKETS_PAGE,
    element: <Tickets />,
  },
  {
    path: ROUTES.TICKETS,
    element: <Tickets />,
  },

  {
    path: ROUTES.PARTICIPATION_TICKETS,
    element: <ParticipationTickets />,
  },
  {
    path: ROUTES.FINANCE,
    element: <Finance />,
  },
];
