import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {loadingText} from "shared/constants/commonText";
import CircularProgress from "@mui/material/CircularProgress";

function SuspenseLoader() {
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height={"100vh"}
      p={4}
      width={"100%"}
    >
      <Stack direction="row" gap={2} alignItems="center">
        <Typography>{loadingText}</Typography>
        <CircularProgress color="primary" size={20} />
      </Stack>
    </Stack>
  );
}

export default SuspenseLoader;
