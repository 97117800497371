import {Components, Theme} from "@mui/material/styles";

export const muiHelperText: Components<Theme>["MuiFormHelperText"] = {
  defaultProps: {},
  styleOverrides: {
    root: {
      lineHeight: "1",
      fontSize: 12,
    },
  },
};
