export const tagTypes_SRM = [
  "userProfile",
  "Project",
  "Ticket",
  "Position",
  "TicketSchema",
  "ConfirmCloseProj",
  "Trade",
  "Procedures",
  "Notification",
  "Estimate",
  "Markers",
  "ProcedureSystemInvoice",
] as const;
