import {initMessageListener} from "redux-state-sync";
import {
  CombinedState,
  EnhancedStore,
  PreloadedState,
  Reducer,
  configureStore,
} from "@reduxjs/toolkit";
import {middlewares} from "./middlewareStore";
import {reducersForStore} from "./storeReducer";
import {listenerMiddleware} from "./middlewares/listenerMiddleware";
import {ReducerManager, createReducerManager} from "./reducerManager";
import {StateSchema} from "./model/types";
import {Parameters} from "@storybook/types";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options listed below
});

export interface ReduxStoreWithManager extends EnhancedStore<StateSchema> {
  reducerManager: ReducerManager;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const reducerManager = createReducerManager(reducersForStore as any);

export const store = configureStore({
  reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
  enhancers: (getDefaultEnhancers) => [...getDefaultEnhancers, sentryReduxEnhancer],
  devTools: true,
  // preloadedState: createInitialState({}),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false})
      .prepend(listenerMiddleware.middleware)
      .concat(middlewares),
});

// @ts-ignore
store.reducerManager = reducerManager;

initMessageListener(store);

export type RootState = ReturnType<typeof store.getState>;

// для сторибука
export const storiesStore = (parameters?: Parameters) =>
  configureStore({
    reducer: reducerManager.reduce as Reducer<CombinedState<StateSchema>>,
    devTools: true,
    // preloadedState: createInitialState({}),
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({serializableCheck: false})
        .prepend(listenerMiddleware.middleware)
        .concat(middlewares),
    preloadedState: parameters?.store?.initialState as PreloadedState<RootState>, // if undefined, just use default state from reducers
  });
