import {Components, Theme} from "@mui/material/styles";

export const muiInputBase: Components<Theme>["MuiInputBase"] = {
  defaultProps: {},

  styleOverrides: {
    root: ({theme}) => ({
      // "&.MuiFormLabel-root": {
      //   lineHeight: 1.1,
      // },
      "&.MuiOutlinedInput-root": {
        "& > fieldset": {
          top: 0,
          legend: {
            display: "none",
          },
        },
      },
      "&.MuiOutlinedInput-root:hover": {
        "& > fieldset": {
          borderColor: theme.palette.primary.main,
        },
      },
      "&.MuiOutlinedInput-root.Mui-disabled": {
        "& > fieldset": {
          borderColor: theme.palette.black800.main,
          backgroundColor: theme.palette.backgroundBlock.inputDisabled,
          opacity: 0.3,
        },
      },
      "&.MuiInputBase-root": {
        "& input": {
          height: "34px",
          paddingTop: 4,
          paddingBottom: 4,
        },
      },
    }),
  },
};
